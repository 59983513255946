@media screen and (max-width: 1068px) {
    #imgs .row {
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      justify-content: center;
    }
  
    #imgs .col-6 {
      width: 100%; 
    }
  
    #imgs img {
      max-width: 100%; 
      height: auto;
    }
    #controllerstyle {
      overflow-y: hidden!important;
    }
  
  }
 