@media screen and (max-width: 480px) {
  .webcam_id_root {
    position: relative;
    height: 1.8cm;
    left: 2.4cm;
    top: 0.5cm;
  }
  .takephoto_id_root{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 1.3cm;
    justify-content: center;
    left: 1cm;
    width: 1cm;
    text-align: center;
    font-size: 6px;
    height: 1.8cm;
  }
  .capture_id_root{
    top: 0.4cm;
    left: -1.4cm;
position: relative;
width: 2cm!important;
height: 1.8cm!important;  }
}

#checkbox_id_root_register{

}


.text-mutedforbreadcrumb {
  text-decoration: none; 
}

.text-mutedforbreadcrumb:hover {
  text-decoration: underline; 
  text-decoration-color: #0095E8!important; /* Sets the underline color to red */
}
