@media screen and (max-width: 768px) {
  .smart {
    display: flex;
    flex-direction: column;
    gap: 1cm;
  }
  #intervalid{
    margin-top: 0.2cm;
  }
  #timerange{
    margin-top: -0.8cm;
  }
}
