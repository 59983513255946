@media (max-width: 576px) {
  .table-wrapper {
    width: 100%;
    overflow-x: auto;

    
  }
  .order_id {
    width: 3cm !important;    
  }
  .table th,
  .table td {
    padding: 8px;
    width: 5cm;
    font-size: 14px;
    text-align: left;
    border: 1px solid #ddd;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .table th,
  .table td {
    padding: 10px;
    font-size: 16px;
    width: 5cm;

  }
}

@media (min-width: 769px) {
  .table th,
  .table td {
    padding: 12px;
    font-size: 18px;

  }
}
